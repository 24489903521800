<template>
    <div>
        <Html class="h-full bg-gray-50"></Html>
        <Body class="h-full"></Body>

        <div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div class="sm:mx-auto sm:w-full sm:max-w-md">
                <img class="mx-auto h-12 w-auto" src="~assets/images/logo.svg" alt="Drive Commerce" />

                <div class="mt-4 text-center">
                    <i class="fa-regular fa-plus text-lg"></i>
                </div>

                <div class="mt-6">
                    <img class="mx-auto w-[276px]" src="~assets/images/oakandeden.webp" alt="Oak and Eden" />
                </div>

                <h2 class="mt-12 text-center text-2xl tracking-tight text-gray-900">Sign in to your account</h2>
            </div>

            <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <vee-form class="space-y-6" @submit="onSubmit">
                        <div>
                            <label for="email" class="block font-medium text-gray-700">Email address</label>
                            <div class="mt-1">
                                <validated-input
                                    id="email"
                                    name="email"
                                    label="Email"
                                    type="email"
                                    autocomplete="username"
                                    rules="required|email"
                                    v-model="email" />
                            </div>
                        </div>

                        <div>
                            <label for="password" class="block font-medium text-gray-700">Password</label>
                            <div class="mt-1">
                                <validated-input
                                    id="password"
                                    name="password"
                                    label="Password"
                                    type="password"
                                    autocomplete="current-password"
                                    rules="required"
                                    v-model="password" />
                            </div>
                        </div>

                        <div>
                            <button type="submit" class="flex w-full justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">Sign in</button>
                        </div>

                        <div v-if="loginError" class="rounded-md bg-red-50 p-4" v-auto-animate>
                            <div class="flex">
                                <div class="flex-shrink-0">
                                    <i class="fa-regular fa-cicle-exclamation text-lg"></i>
                                </div>
                                <div class="ml-3">
                                    <h3 class="text-sm font-medium text-red-800">Could not sign in</h3>
                                    <div class="mt-2 text-sm text-red-700">
                                        <ul role="list" class="list-disc space-y-1 pl-5">
                                            <li>Please check your email and password</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </vee-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Form } from 'vee-validate';

    export default {
        components: {
            VeeForm: Form,
        },

        data() {
            return {
                email: '',
                password: '',
                loginError: false,
            };
        },

        methods: {
            onSubmit() {
                $fetch('/api/login', {
                    method: 'POST',
                    body: {
                        email: this.email,
                        password: this.password,
                    },
                }).then(() => {
                    this.loginError = false;

                    navigateTo('/');
                }).catch(() => {
                    this.loginError = true;
                });
            },
        },
    };
</script>
